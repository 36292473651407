import { ThemeProvider } from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { theme } from './_styles/theme.styles';
import { AppScreen } from './screens/app-screen';
import { CategorySelectionIndex } from './pages/userJourney/category-selection';
import { ServiceTypeEnum } from './_common/enum/setviceTypeEnum';
import { JourneyIndex } from './pages/userJourney/journey-index';
import { AppointmentManage } from './pages/appointment/appointment-manage';
import { ErrorPage } from './pages/userJourney/userJourneyComponents/errorPage';
import { AppointmentCancelled } from './pages/appointment/components/appointmentCancelled';
import { AppointmentCancellationWarning } from './pages/appointment/components/appointmentCancellationWarning';

function App() {
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Routes>
                    <Route path="" element={<AppScreen />}>
                        <Route path="/" element={<CategorySelectionIndex />} />
                        <Route path="/category" element={<CategorySelectionIndex />} />
                        <Route path="/mortgage" element={<JourneyIndex serviceType={ServiceTypeEnum.Mortgage}/>} />
                        <Route path="/financialplanning" element={<JourneyIndex serviceType={ServiceTypeEnum.Financial}/>} />
                        <Route path="/investment" element={<JourneyIndex serviceType={ServiceTypeEnum.Investment}/>} />
                        <Route path="/pension" element={<JourneyIndex serviceType={ServiceTypeEnum.Pension}/>} />
                        <Route path="/protection" element={<JourneyIndex serviceType={ServiceTypeEnum.Protection}/>} />
                        <Route path="/businessowner" element={<JourneyIndex serviceType={ServiceTypeEnum.Business}/>} />
                        <Route path="/existingclients" element={<JourneyIndex serviceType={ServiceTypeEnum.Existing}/>} />
                        <Route path="/error" element={<ErrorPage />} />
                        <Route path="/appointmentManage/:token" element={<AppointmentManage />} />

                        <Route path="/appointmentCancel" element={<AppointmentManage />} />

                    </Route>
                </Routes>
            </BrowserRouter>
        </ThemeProvider >
    );
}

export default App;
