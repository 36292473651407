import { accountService } from "../_services/accounts.service";
export interface IPrimaryNavInterface {
    icon?: string;
    imageLocation: string;
    whiteImageLocation?: string,
    name: string;
    mobileName?: string;
    url: string;
    onClick?: any;
    png?: string;
    isPublic?: boolean;
}

const CATEGORY_NAV: IPrimaryNavInterface[] = [
    { name: 'Mortgage', mobileName: 'Mortgage', url: 'mortgage', imageLocation: require('../_assets/images/svgBlueIcons/Icon_Mortgage_consultation.svg').default, isPublic: false, whiteImageLocation: require('../_assets/images/svgWhiteIcons/Icon_Mortgage_consultation_white.svg').default },
    { name: 'Financial Planning', mobileName: 'Financial Planning', url: 'financialplanning', imageLocation: require('../_assets/images/svgBlueIcons/Icon_Financial_planning.svg').default, isPublic: false, whiteImageLocation: require('../_assets/images/svgWhiteIcons/Icon_Financial_planning_white.svg').default },
    { name: 'Investment', mobileName: 'Investment', url: 'investment', imageLocation: require('../_assets/images/svgBlueIcons/Icon_Investment_consultation.svg').default, isPublic: false, whiteImageLocation: require('../_assets/images/svgWhiteIcons/Icon_Investment consultation_white.svg').default },
    { name: 'Pension', mobileName: 'Pension', url: 'pension', imageLocation: require('../_assets/images/svgBlueIcons/Icon_Pension_consultation.svg').default, isPublic: false, whiteImageLocation: require('../_assets/images/svgWhiteIcons/Icon_Pension_consultation_white.svg').default },
    { name: 'Protection', mobileName: 'Protection', url: 'protection', imageLocation: require('../_assets/images/svgBlueIcons/Icon_Protection_consultation.svg').default, isPublic: false, whiteImageLocation: require('../_assets/images/svgWhiteIcons/Icon_Protection_consultation_white.svg').default },
    { name: 'Business Owner', mobileName: 'Business Owner', url: 'businessowner', imageLocation: require('../_assets/images/svgBlueIcons/Icon_Business_Owner_consultation.svg').default, isPublic: false, whiteImageLocation: require('../_assets/images/svgWhiteIcons/Icon_Business_Owner_consultation_white.svg').default },
    { name: 'Existing Clients', mobileName: 'Existing Clients', url: 'existingclients', imageLocation: require('../_assets/images/svgBlueIcons/Icon_Existing_clients.svg').default, isPublic: false, whiteImageLocation: require('../_assets/images/svgWhiteIcons/Icon_Existing_clients_white.svg').default },
];

export { CATEGORY_NAV };

